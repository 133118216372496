<h1 class="page-title">JULY 2024 Events</h1>

<div style="background-color: black; padding-bottom: 100px; margin-bottom: 100px;" class="don-tile">

    <!-- <h2 style="color: white" class="title is-underlined">No Live music Easter Weekend of 7th & 9th</h2> -->

    <hr>
    <!-- <h1 style="color: white;" class="title m-4">Performing Classic Rock Covers</h1> -->
    <h1 style="color: rgb(36, 216, 30);" class="title m-4">THE WILSON TWINS</h1>
    <h2 style="color: white" class="subtitle">Friday 5th July 6pm - 9pm</h2>

    <img id="band-pic" src="../../assets/images/events/image-coming-soon-placeholder.png">

    <hr>
    <h1 style="color: white;" class="title m-4">Acoustic Music & Solo Guitarist</h1>
    <h1 style="color: rgb(81, 219, 122);" class="title m-4">Sean McLaughlin</h1>
    <h2 style="color: white" class="subtitle">Saturday 7th July 1pm - 4pm<br>Sunday 21st July 1pm - 4pm</h2>

    <img id="band-pic" src="../../assets/images/events/sean_mclaughlin.jpg">

    <hr>
    <h1 style="color: white;" class="title m-4">Solo Artist</h1>
    <h1 style="color: rgb(81, 219, 122);" class="title m-4">Andrew Brown</h1>
    <h2 style="color: white" class="subtitle">Friday 12th July 6pm - 9pm</h2>

    <img id="band-pic" src="../../assets/images/events/image-coming-soon-placeholder.png">

    <hr>
    <h1 style="color: white;" class="title m-4">Playing Original Smash Hits</h1>
    <h1 style="color: rgb(240, 93, 93);" class="title m-4">VERY HANDSOME MEN</h1>
    <h2 style="color: white" class="subtitle">Sunday 14th July 1pm - 4pm</h2>

    <img id="band-pic" src="../../assets/images/events/Very Handsome Men.jpg">

    <hr>
    <h1 style="color: white;" class="title m-4">Covers of all kind!</h1>
    <h1 style="color: rgb(36, 216, 30);" class="title m-4">EL Muso Solo</h1>
    <h2 style="color: white" class="subtitle">Friday 19th July 6pm - 9pm<br>Sunday 28th July</h2>

    <img id="band-pic" src="../../assets/images/events/EL_Muso_Solo.jpg">

    <!-- <hr>
    <h1 style="color: white;" class="title m-4">Performing Classic Rock Covers</h1>
    <h1 style="color: rgb(36, 216, 30);" class="title m-4">THE JADED CATS</h1>
    <h2 style="color: white" class="subtitle">Friday 5th April 7pm - 10pm</h2>

    <img id="band-pic" src="../../assets/images/events/Jaded Cats.jpg"> -->

    <!-- <hr>
    <h1 style="color: white;" class="title m-4">Soul, Blues, Jazz, Funk, & Rock</h1>
    <h1 style="color: rgb(231, 126, 65);" class="title m-4">ANTHONY & SON</h1>
    <h2 style="color: white" class="subtitle">Friday 12th April 6pm - 9pm</h2>

    <img id="band-pic" src="../../assets/images/events/Anthony Wilson.jpg"> -->

    <!-- <hr>
    <h1 style="color: white;" class="title m-4">Music from the motion picture 'BLINDER'</h1>
    <h1 style="color: rgb(213, 216, 30);" class="title m-4">FARREN JONES</h1>
    <h2 style="color: white" class="subtitle">Friday 19th April 6pm - 9pm</h2>

    <img id="band-pic" src="../../assets/images/events/FarrenJones.jpg"> -->

    <hr>
    <h1 style="color: white;" class="title m-4">The Greatest Rockshow of the 60's, 70's & 80's</h1>
    <h1 style="color: rgb(213, 216, 30);" class="title m-4">Einsteins Toyboys</h1>
    <h2 style="color: white" class="subtitle">Friday 26th July 7.30pm - 10.30pm</h2>

    <img id="band-pic" src="../../assets/images/events/Einnsteins_Toyboys.png">

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">Playing Original Smash Hits</h1>
        <h1 style="color: rgb(81, 219, 122);" class="title m-4">Willy J</h1>
        <h2 style="color: white" class="subtitle">6pm - 9pm Friday 14th April<br>6pm - 9pm Friday 28th April</h2>

        <img id="band-pic" src="../../assets/images/events/Willy j.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">...</h1>
        <h1 style="color: rgb(81, 219, 122);" class="title m-4">Phil Para</h1>
        <h2 style="color: white" class="subtitle">1pm - 4pm Sunday 23rd April</h2>

        <img id="band-pic" src="../../assets/images/events/Phil Para.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">...</h1>
        <h1 style="color: rgb(81, 219, 122);" class="title m-4">Scotty & Stevo</h1>
        <h2 style="color: white" class="subtitle">1pm - 4pm Sunnday 5th March</h2>

        <img id="band-pic" src="../../assets/images/events/Steve & Scotty.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">Rock'n'Roll for the Soul</h1>
        <h1 style="color: rgb(81, 219, 122);" class="title m-4">TUXEDOS</h1>
        <h2 style="color: white" class="subtitle">6.30pm - 9.30pm Friday 24th March</h2>

        <img id="band-pic" src="../../assets/images/events/Tuxedos.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">The Greatest Classic Rock 60's 70's 80's</h1>
        <h1 style="color: rgb(216, 197, 30);" class="title m-4">ALFI ROCKER</h1>
        <h2 style="color: white" class="subtitle">3pm - 6pm Sunday 4th December</h2>

        <img id="band-pic" src="../../assets/images/events/Alfi Rocker.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">Playing Original Smash Hits</h1>
        <h1 style="color: rgb(75, 193, 223);" class="title m-4">THE BAYSY HOWLERS</h1>
        <h2 style="color: white" class="subtitle">7pm - 10pm Friday 26th August</h2>

        <img id="band-pic" src="../../assets/images/events/Baysy Howlers.jpg"> -->

    <!-- <hr>
        <h1 style="color: white;" class="title m-4">Covers of all k!ind</h1>
        <h1 style="color: rgb(36, 216, 30);" class="title m-4">EL Muso Solo</h1>
        <h2 style="color: white" class="subtitle">6pm - 9pm Friday 9th June</h2>

        <img id="band-pic" src="../../assets/images/events/EL_Muso_Solo.jpg"> -->

    <!-- GrID Formation Below -->

    <!-- <div class="columns">
        <div class="column">
            <h1 style="color: white;" class="title m-4">Soul, Blues, Jazz, Funk, & Rock</h1>
            <h1 style="color: rgb(231, 126, 65);" class="title m-4">Anthony Wilson</h1>
            <h2 style="color: white" class="subtitle">Friday 6th October 6pm - 9pm<br>Friday 27th October 6pm - 9pm</h2>

            <img id="band-pic" src="../../assets/images/events/Anthony Wilson.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">Acoustic Music & Solo Guitarist</h1>
            <h1 style="color: rgb(81, 219, 122);" class="title m-4">Sean McLaughlin</h1>
            <h2 style="color: white" class="subtitle">Sunday 8th October 1pm - 4pm</h2>

            <img id="band-pic" src="../../assets/images/events/sean_mclaughlin.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">No Bands Private Function</h1>
            <h2 style="color: white" class="subtitle">Friday 13th October</h2>

            <img id="band-pic" src="../../assets/images/events/private_function.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">Covers of all k!ind</h1>
            <h1 style="color: rgb(36, 216, 30);" class="title m-4">EL Muso Solo</h1>
            <h2 style="color: white" class="subtitle">Sunday 15th October 1pm - 4pm</h2>

            <img id="band-pic" src="../../assets/images/events/EL_Muso_Solo.jpg">
        </div>
    </div>

    <hr>

    <div class="columns">
        <div class="column">
            <h1 style="color: white;" class="title m-4">The Greatest Rockshow of the 60's, 70's & 80's</h1>
            <h1 style="color: rgb(213, 216, 30);" class="title m-4">Einsteins Toyboys</h1>
            <h2 style="color: white" class="subtitle">Friday 20th October 7.30pm - 10.30pm</h2>

            <img id="band-pic" src="../../assets/images/events/Einnsteins_Toyboys.png">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">Music from the motion picture 'BLINDER'</h1>
            <h1 style="color: rgb(213, 216, 30);" class="title m-4">Farren Jones</h1>
            <h2 style="color: white" class="subtitle">Sunday 22nd October 1pm - 4pm</h2>

            <img id="band-pic" src="../../assets/images/events/FarrenJones.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">Playing Original Smash Hits</h1>
            <h1 style="color: rgb(71, 68, 219);" class="title m-4">Willy J</h1>
            <h2 style="color: white" class="subtitle">1pm - 4pm Sunday 29th October</h2>

            <img id="band-pic" src="../../assets/images/events/Willy j.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">Playing Original Smash Hits</h1>
            <h1 style="color: rgb(240, 93, 93);" class="title m-4">Very Handsome Men</h1>
            <h2 style="color: white" class="subtitle">Sunday 29th October 1pm - 4pm</h2>

            <img id="band-pic" src="../../assets/images/events/Very Handsome Men.jpg">
        </div>
        <div class="column">
            <h1 style="color: white;" class="title m-4">50's to Current Tunes</h1>
            <h1 style="color: rgb(36, 216, 30);" class="title m-4">EL Muso & Floyd</h1>
            <h2 style="color: white" class="subtitle">6pm - 9pm Friday 9th June</h2>

            <img id="band-pic" src="../../assets/images/events/ELMuso&Floyd.jpg">
        </div>
    </div> -->

    <!-- <div class="modal" [ngClass]="{ 'is-active': isModalActive }">
        <div class="modal-background" (click)="closeInfo()"></div>
        <div class="modal-content has-background-black">
            <h1 style="color: white;" class="title m-1">50's to Current Tunes</h1>
            <h1 style="color: rgb(36, 216, 30);" class="title m-4">EL Muso & FLOYD</h1>
            <h2 style="color: white" class="subtitle">1pm - 4pm Sunday 2nd April</h2>
            <img src="../../assets/images/events/ELMuso&Floyd.jpg">
        </div>
        <button class="modal-close is-large" aria-label="close"></button>
    </div> -->

</div>