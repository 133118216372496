<div class="contain">
  <header (click)="responsive()">
      <div id="nav" role="navigation" class="navbar" [ngClass]="{ 'dropdown': isNavActive }">
      <a class="icon"><i #navbar class="fa fa-bars"><span>Menu</span></i></a>
      <div routerLink="functions">Functions</div>
      <div routerLink="events">Events</div>
      <div routerLink="food">Food</div>
      <div routerLink="home">Home</div>
      <div routerLink="drinks">Drinks</div>
      <div routerLink="gallery">Gallery</div>
      <div routerLink="contact">Contact</div>
    </div>
  </header>
  <div class="middle-space">
    <router-outlet></router-outlet>
  </div>
</div>