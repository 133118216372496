import { Component, OnInit } from '@angular/core';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation, NgxGalleryImageSize } from 'ngx-gallery-9';

@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {

    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];

    constructor() { }

    ngOnInit(): void {
        this.loadGallery();
    }

    loadGallery(): void {

        this.galleryOptions = [
            {
                width: '70%',
                height: '85%',
                thumbnailsColumns: 4,
                imageSize: NgxGalleryImageSize.Cover,
                thumbnailSize: NgxGalleryImageSize.Cover,
                imageInfinityMove: true,
                previewInfinityMove: true,
                imageAnimation: NgxGalleryAnimation.Rotate,
                previewFullscreen: true,
                previewCloseOnClick: true
            },
            {
                breakpoint: 900,
                width: '90%',
                height: '85%',
                imagePercent: 80,
                thumbnailsPercent: 30,
                thumbnailsMargin: 10,
            },
            {
                breakpoint: 600,
                width: '90%',
                height: '300px',
            }
        ];

        this.galleryImages = [
            {
                small: '../assets/images/donny (2).jpg',
                medium: '../assets/images/donny (2).jpg',
                big: '../assets/images/donny (2).jpg'
            },
            {
                small: '../assets/images/donny (3).jpg',
                medium: '../assets/images/donny (3).jpg',
                big: '../assets/images/donny (3).jpg'
            },
            {
                small: '../assets/images/donny (4).jpg',
                medium: '../assets/images/donny (4).jpg',
                big: '../assets/images/donny (4).jpg'
            },
            {
                small: '../assets/images/donny (7).jpg',
                medium: '../assets/images/donny (7).jpg',
                big: '../assets/images/donny (7).jpg'
            },
            {
                small: '../assets/images/donny (8).jpg',
                medium: '../assets/images/donny (8).jpg',
                big: '../assets/images/donny (8).jpg'
            },
            {
                small: '../assets/images/donny (9).jpg',
                medium: '../assets/images/donny (9).jpg',
                big: '../assets/images/donny (9).jpg'
            },
            {
                small: '../assets/images/donny (10).jpg',
                medium: '../assets/images/donny (10).jpg',
                big: '../assets/images/donny (10).jpg'
            },
            {
                small: '../assets/images/donny (12).jpg',
                medium: '../assets/images/donny (12).jpg',
                big: '../assets/images/donny (12).jpg'
            },
            {
                small: '../assets/images/donny (13).jpg',
                medium: '../assets/images/donny (13).jpg',
                big: '../assets/images/donny (13).jpg'
            },
            {
                small: '../assets/images/donny (14).jpg',
                medium: '../assets/images/donny (14).jpg',
                big: '../assets/images/donny (14).jpg'
            },
            {
                small: '../assets/images/food (5).jpg',
                medium: '../assets/images/food (5).jpg',
                big: '../assets/images/food (5).jpg'
            },
            {
                small: '../assets/images/food (2).jpg',
                medium: '../assets/images/food (2).jpg',
                big: '../assets/images/food (2).jpg'
            },
            {
                small: '../assets/images/food (4).jpg',
                medium: '../assets/images/food (4).jpg',
                big: '../assets/images/food (4).jpg'
            }
            ,
            {
                small: '../assets/images/food (1).jpg',
                medium: '../assets/images/food (1).jpg',
                big: '../assets/images/food (1).jpg'
            }
            ,
            {
                small: '../assets/images/food (6).jpg',
                medium: '../assets/images/food (6).jpg',
                big: '../assets/images/food (6).jpg'
            }
            ,
            {
                small: '../assets/images/food (7).jpg',
                medium: '../assets/images/food (7).jpg',
                big: '../assets/images/food (7).jpg'
            },
            {
                small: '../assets/images/food (8).jpg',
                medium: '../assets/images/food (8).jpg',
                big: '../assets/images/food (8).jpg'
            },
            {
                small: '../assets/images/food (11).jpg',
                medium: '../assets/images/food (11).jpg',
                big: '../assets/images/food (11).jpg'
            }
            ,
            {
                small: '../assets/images/food (12).jpg',
                medium: '../assets/images/food (12).jpg',
                big: '../assets/images/food (12).jpg'
            }
        ];
    }

}
