<div class="home-container">
    <div class="header-back">
        <header><img src="../../assets/images/logo.JPG">
            <h3>Casual Dining - Pub Food - Modern Australian</h3>
        </header>
    </div>
<!-- 
    <div class="xmas-border">
        <h3 class="h3-title xmas" style="color: rgb(25, 15, 83);">Customers Please Note</h3>
        <p style="font-weight: bold;font-size: 1.8em;">The Donnnybrook Hotel will be
            <br><span style="color: rgb(25, 15, 83); font-size: xxx-large;">"CLOSED"</span>
            <br>from 9pm Sunday 24th December<br>and re-open<br>at 11am on Friday 5th January
        </p>
    </div> -->

    <br>
    <br>
    <div class="rgb-template" routerLink="/events">
        <div class="rgb-back">
            <h3 class="h3-title">DONNYBROOK HOTEL<br>LIVE EVENTS</h3>
            <p>Click Here for latest news!</p>
        </div>
    </div>
    <br>
    <br>

    <div class="don-brief">
        <h1 class="title m-4">The Donnybrook</h1>
        <p>The Donnybrook Hotel was established in 1863 and is Melbourne’s pre-eminent outer suburban pub, renowned
            for good food, good service and good times. Set in a semi-rural location within easy reach of Melbourne’s
            northern and western suburbs, the Donnybrook offers a sophisticated a la carte menu that includes
            traditional pub favorites.</p>
        <br>
        <p>Newly renovated, the Donnybrook has a function room to cater for 60 to 80 people indoors and a spacious beer
            garden that can host 100+ that also hosts
            live music every week and an entertainment area with arcade machines where children can play.</p>
        <br>
        <p>For a great night with family and friends, please try the Donnybrook. We make the good times happen.</p>

    </div>

    <br>
    <br>

    <img id="pub-front" src="../../assets/images/donny-front.JPG">

    <h2 class="title m-4">TRADING HOURS</h2>
    <p>KITCHEN OPEN: 11am til 9pm</p>
    <p>MONDAY: 11am til Late</p>
    <p>TUESDAY: 11am til Late</p>
    <p>WED : 11am til Late</p>
    <p>THURSDAY: 11am til Late</p>
    <p>FRIDAY: 11am til Late</p>
    <p>SATURDAY: 11am til Late</p>
    <p>SUNDAY: 11am til Late</p>

</div>