<!-- <h1 class="page-title">Contact us</h1>
<div class="form-container">
    <form class="form-group" #contactForm="ngForm" (ngSubmit)="sendEmail(contactForm.form);">
        <div class="form-group">
            <input type="text" placeholder="{{name}}" name="name" ngModel #nameField="ngModel" required>
        </div>
        <div class="form-group">
            <input type="email" placeholder="{{email}}" name="email" ngModel #emailField="ngModel" required>
        </div>
        <div class="form-group">
            <textarea rows="3" placeholder="{{message}}" name="message" ngModel #messageField="ngModel"
                required></textarea>
        </div>
        <div class="send-copy">
            <label id="send-lbl" for="send-copy">Send me a copy of this message<input id="send-chk" name="send-copy" type="checkbox"></label>            
        </div>
        <div class="form-group">
            <button type="submit" class="btn send-btn" [disabled]="!contactForm.valid">Send</button>
            <button type="button" onClick="window.open('//facebook.com/Donnybrookhotel69/')" class="btn facebook-btn">Facebook</button>
        </div>
    </form>
</div> -->


<!-- <h1 class="page-title">Contact us</h1>
<div class="map-container">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.5296216150728!2d144.9681871158377!3d-37.
        542584026348635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad652a693f1abaf%3A0xd67ccdd33bac3266!
        2sDonnybrook%20Hotel!5e0!3m2!1sen!2sau!4v1601140343236!5m2!1sen!2sau" frameborder="0"
        style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    <div class="address">
        <h1><span>D</span>onnybrook <span>H</span>otel</h1>
        <p>825 Donnybrook Rd, <br>VIC, 3064<br>(03) 9745 2380</p>
        <p>
            <a href="mailto:donnybrook@donnybrookhotel.com.au" target="_top">
                donnybrook@donnybrookhotel.com.au</a>
        </p>
    </div>
</div> -->

<h1 class="page-title">Contact us</h1>
<div class="map-container">
    <div class="columns">
        <div class="column">
            <div class="contact-info is-size-3-desktop">
                <p class="donny-title"><span>D</span>onnybrook <span>H</span>otel</p>
                <p>825 Donnybrook Rd, <br>VIC, 3064<br>(03) 9745 2380</p>
                <p>
                    <a href="mailto:donnybrook@donnybrookhotel.com.au" target="_top">
                        donnybrook@donnybrookhotel.com.au</a>
                </p>
            </div>
        </div>
        <div class="column">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.5296216150728!2d144.9681871158377!3d-37.
            542584026348635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad652a693f1abaf%3A0xd67ccdd33bac3266!
            2sDonnybrook%20Hotel!5e0!3m2!1sen!2sau!4v1601140343236!5m2!1sen!2sau" frameborder="0"
                style="border:0; height: 350px; width: 90%" allowfullscreen="" aria-hidden="false"
                tabindex="0"></iframe>
        </div>

    </div>

</div>