import { Component, ElementRef, ViewChild, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'DonnybrookHotelWebsite-angular';

  isNavActive: boolean;

  constructor(private renderer: Renderer2) {

  }

  responsive() {
    var x = document.getElementById("nav");

    console.log(x.className);    

    if (x.className == "navbar dropdown") {
      this.isNavActive = false;
    }
    else {
      this.isNavActive = true;
    }
  }

}
