import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})
export class EventsComponent implements OnInit {

  isModalActive: boolean;

  constructor() { }

  ngOnInit(): void {
  }

  showInfo(): void {
    this.isModalActive = true;
  }

  closeInfo() : void {
    this.isModalActive = false;
  }
}
