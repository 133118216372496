import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  name: string = "Name";
  email: string = "E-mail";
  message: string = "Message";

  constructor() { }

  ngOnInit(): void {
  }

  sendEmail(form) {
    console.log(form.value);
    form.reset();

    alert("Your message has been sent to the Donnybrook, you will hear from us soon!");
  }
}
