<h1 class="page-title" style="color: rgb(151, 5, 34);">UNNDER CONNSTRUCTION</h1>


<!-- <h1 class="page-title">Functions</h1>
<div class="don-tile">
    <h1 class="title">Catering for All Function Types</h1>
    <p class="content-txt">Whether it’s an Engagement, Anniversary, Wedding, Work Party, Birthday we can cater it for you, no Party is too Big or Too Small!</p>
    <br>
    <p class="content-txt">At the Donnybrook Hotel we cater for all your function needs, we offer flexibility on functions that most others cannot. As our
        packages are completely customizable, it means we will work with you to ensure you have a thoroughly enjoyable
        experience well within your budgets needs. We are happy to accommodate your needs as best as we can, as we aim
        to offer a service no other venue can.</p>
    <br>
    <p class="content-txt">We provide food & drinks to suit all dietary requirements & party style. We have several
        different sit-down packages from our A La Carte to a custom designed menu.</p>
    <br>
    <p class="content-txt">We have a variety of Finger Food & Custom BBQ Menus You can choose to offer your guests a
        Drink Package or a Bar Tab or they can pay for their own drinks, which ever suits your needs. We also offer
        Barista style Coffee & Tea.</p>

    <h2>Here at the Donnybrook we understand that not every party wants to have the same menu.</h2>
    <br>
    <p class="content-txt">If there is something that you've had here before and love feel free to ask and we can always
        add it on.</p>
    <h2 style="font-size: large; color:rgb(124, 0, 25);">A non-refundable deposit must be made to secure bookings
        <br>(Prices subject to change on alterations)
    </h2>

    <br>
    <h2>All Bookings to be done via direct Email</h2>
  
    <a href="mailto:donnybrook@donnybrookhotel.com.au" target="_top">
        <span class="click">Click Here to Book Now</span></a>
        <h4 class="click" routerLink="/contact">Click Here for our Contacts Page</h4> 
        <br><br><br>


    <h2>See Below For Menu and Pricing.</h2>
    <br>
</div>
<br><br>
<h1 class="page-title">Sit Down Menu</h1>
<div class="wrap">
    <iframe class="menu" frameborder="0" src="../assets/documents/Functions_Menu_Sit_Down.pdf#view=FitH">
    </iframe>
</div>

<h1 class="page-title">Finger Food Menu</h1>
<div class="wrap">
    <iframe class="menu" frameborder="0" src="../assets/documents/Functions_Menu_Finger_Food_Beverage.pdf#view=FitH">
    </iframe>
</div> -->