import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ContactComponent } from './contact/contact.component';
import { DrinksComponent } from './drinks/drinks.component';
import { EventsComponent } from './events/events.component';
import { HomeComponent } from './home/home.component';
import { FoodComponent } from './food/food.component';
import { FunctionsComponent } from './functions/functions.component';
import { GalleryComponent } from './gallery/gallery.component';
import { AdminComponent } from './admin/admin.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  { path: 'food', component: FoodComponent },
  { path: 'drinks', component: DrinksComponent },
  { path: 'events', component: EventsComponent },
  { path: 'home', component: HomeComponent },
  { path: 'functions', component: FunctionsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'admin', component: AdminComponent },
  {
    path: '**',
    redirectTo: '/home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
